<template>
	<div class="page product-apply">
		<div class="container">
			<van-form ref="applyForm" @submit="onSubmit">
				<van-cell-group inset>
					<h3>订单信息</h3>
					<van-cell :border="false">
						<template #title>
							<span>订单号：</span>{{ orderInfo.mchOrderNo }}
						</template>
					</van-cell>

					<van-cell :border="false">
						<template #title>
							<span>下单时间：</span>{{ orderInfo.createdAt }}
						</template>
					</van-cell>
				</van-cell-group>
				<van-cell-group inset>
					<h3>选择支付方式</h3>
					<van-radio-group v-model="payType">
						<van-cell-group
							class="paytype-radio-group"
							:border="false"
						>
							<van-cell
								title="微信支付"
								clickable
								@click="payType = '1'"
							>
								<van-icon
									size="24"
									name="wechat-pay"
									class="radio-icon"
									color="#09bb07"
								/>
								<template #right-icon>
									<van-radio name="1" />
								</template>
							</van-cell>
						</van-cell-group>
					</van-radio-group>
				</van-cell-group>
			</van-form>
		</div>
		<div class="end">
			<div class="settle">
				<van-button
					native-type="submit"
					type="info"
					round
					class="settle-submit"
					@click="onSubmit"
					>确认支付</van-button
				>
				支付金额：<strong>{{ orderInfo.amount }}元</strong>
			</div>
		</div>
	</div>
</template>

<script>
import { Toast } from "vant";
import config from "@/config";
import { getPayWxH5OrderInfo, getPayPackage } from "@/api/api";

export default {
	data() {
		return {
			payOrderId: "",
			// 订单详情+this.wxCode
			orderInfo: {
				mchOrderNo: "", //下单时间	string	@mock=2021-10-21 10:16:55
				amount: "", //总保费	string	@mock=118
				createdAt: "", //下单时间	string	@mock=2021-10-21 10:16:55
				payUrl: "", //总保费	string	@mock=118
				wayCode: "",
			},
			payType: "1",
			// 获取支付参数
			resData: {},
		};
	},
	props: {},
	created() {},
	mounted() {
		let { payOrderId } = this.$route.query;

		if (payOrderId) {
			this.payOrderId = payOrderId;
			this.getPayOrderInfo();
		}

		document.querySelector("#app").style.height = window.innerHeight + 'px';
	},
	computed: {},
	components: {},
	methods: {
		getPayOrderInfo() {
			getPayWxH5OrderInfo({
				payOrderId: this.payOrderId,
			})
				.then((res) => {
					this.orderInfo.mchOrderNo = res.mchOrderNo;
					this.orderInfo.amount = res.amount / 100;
					this.orderInfo.createdAt = this.dateToString(
						new Date(res.createdAt)
					);
					this.orderInfo.payUrl = res.payUrl;
					this.orderInfo.wayCode = res.wayCode;
				})
				.catch((res) => {
					console.log(res);
					Toast({
						message: res.msg,
						duration: 2000,
					});
				});
		},
		onSubmit() {
			window.location.replace(this.orderInfo.payUrl);
		},
		dateToString(date) {
			let year = date.getFullYear();
			let month = (date.getMonth() + 1).toString();
			let day = date.getDate().toString();
			let hour = date.getHours().toString();
			let minute = date.getMinutes().toString();
			let second = date.getSeconds().toString();

			month = month.length == 1 ? "0" + month : month;
			day = day.length == 1 ? "0" + day : day;
			hour = hour.length == 1 ? "0" + hour : hour;
			minute = minute.length == 1 ? "0" + minute : minute;
			second = second.length == 1 ? "0" + second : second;

			return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.page {
	background-color: #f8f9fb;
}
.product-apply {
	height: 100%;
}
.container {
	overflow: auto;
	color: #111a34;
	.van-cell-group--inset,
	.insureAdd {
		margin: 10px 16px;
	}
	.insureAdd {
		margin: 0 16px 16px;
		width: 343px;
		border: none;
		border-radius: 8px;
		font-size: 14px;
		color: #1e92ff;
	}
	h3 {
		margin: 10px 0 24px 14px;
		line-height: 27px;
		font-size: 18px;
		color: #121130;
		em {
			position: absolute;
			margin-top: 4px;
			margin-left: 5px;
			display: inline-block;
			min-width: 18px;
			height: 18px;
			line-height: 18px;
			text-align: center;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			color: #fff;
			background-color: #1e92ff;
			border-radius: 9px;
		}
	}
	p {
		line-height: 24px;
		font-size: 14px;
	}
}
// 尾部固定
.end {
	position: fixed;
	z-index: 9;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
	bottom: 0;
}
.settle,
.settle-submit {
	line-height: 40px;
	overflow: hidden;
}
.settle {
	width: 100%;
	height: 72px;
	padding: 14px 16px 14px 20px;
	font-size: 14px;
	box-sizing: border-box;
	strong {
		font-size: 16px;
		color: #f00;
	}
	.settle-submit {
		float: right;
		height: 40px;
	}
	.van-button__text {
		padding: 0 10px;
	}
}
.paytype-radio-group {
	.van-cell__value {
		position: absolute;
	}
	.van-cell__title {
		padding-left: 30px;
		font-size: 16px;
	}
}
</style>
